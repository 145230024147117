import React from "react";  
import backgroundVideo from "./background.mp4";
import "./Home.css"; 

const Home = () => {
  return (
    <div className="container">
      <nav>
        {/* <img src={logo} alt="Logo" className="logo" /> */}
        <h1>Kreeda.ai</h1>
        <div className="nav-links">
          <a href="/">Home</a>
          <a href="/">About</a> {/* Ensure you have an About component */}
          {/* <a href="/Next">Demo</a>
          <a href="/user/home">Exercises</a>
          <a href="/login">Login/Signup</a> */}
        </div>
      </nav>
      <div className="background-container">
        <video autoPlay loop muted playsInline className="background">
          <source src={backgroundVideo} type="video/mp4" />
        </video>
      </div>
      <div className="content"> 
        <h1>Kreeda.ai </h1>
        <h3>
          Empowering your fitness journey with AI-driven workouts and
          personalized guidance.
        </h3>
        <h3>
          Experience the future of fitness with Kreeda.ai: where technology
          meets your training goals.
        </h3>
        <a href="/user/home" className="explore">
          Coming Soon ...  
        </a>
      </div>
    </div>
  );
};

export default Home;
